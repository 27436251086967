import { ActionTypes } from '@/store/actionTypes'
import {
  ContactCompensation,
  ContactCompensationActionTypes,
} from '@/store/contactCompensation/types'

const initialState: ContactCompensation = {
  tel: '',
  email: '',
  time_of_notice: new Date(),
  time_of_emergence: new Date(),
  police: '',
  receipt_number: '',
  is_use_supay: '',
  balance: '',
  bank: '',
  total_damage: '',
  has_compensation: '',
  is_terms_accept: false,
  detail: '',
  require_files: [],
  other_files: [],
}

export const ContactCompensationReducer = (
  state = initialState,
  action: ContactCompensationActionTypes
): ContactCompensation => {
  switch (action.type) {
    case ActionTypes.contactCompensation:
      return {
        ...state,
        tel: action.payload.tel,
        email: action.payload.email,
        time_of_notice: action.payload.time_of_notice,
        time_of_emergence: action.payload.time_of_emergence,
        police: action.payload.police,
        receipt_number: action.payload.receipt_number,
        is_use_supay: action.payload.is_use_supay,
        balance: action.payload.balance,
        bank: action.payload.bank,
        total_damage: action.payload.total_damage,
        has_compensation: action.payload.has_compensation,
        is_terms_accept: action.payload.is_terms_accept,
        detail: action.payload.detail,
        require_files: action.payload.require_files,
        other_files: action.payload.other_files,
      }
    case ActionTypes.clearState:
      return (state = initialState)
    default:
      return state
  }
}
