import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = createUploadLink({
  uri: 'https://dango-dev.su-pay.jp/graphql',
})

const authLink = setContext((_request, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-SUPAY': 'trial-hp',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
