import { ActionTypes } from '@/store/actionTypes'
import {
  ContactCancel,
  ContactCancelActionTypes,
} from '@/store/contactCancel/types'

const initialState: ContactCancel = {
  name: '',
  birthday: new Date('1980/01/01'),
  tel: '',
  email: '',
  zip_code: '',
  address: '',
  require_files: [],
  other_files: [],
}

export const ContactCancelReducer = (
  state = initialState,
  action: ContactCancelActionTypes
): ContactCancel => {
  switch (action.type) {
    case ActionTypes.contactCancel:
      return {
        ...state,
        name: action.payload.name,
        birthday: action.payload.birthday,
        tel: action.payload.tel,
        email: action.payload.email,
        zip_code: action.payload.zip_code,
        address: action.payload.address,
        require_files: action.payload.require_files,
        other_files: action.payload.other_files,
      }
    case ActionTypes.clearState:
      return (state = initialState)
    default:
      return state
  }
}
