import { ActionTypes } from '@/store/actionTypes'
import {
  ContactPhoneChange,
  ContactsPhoneChangeActionTypes,
} from '@/store/contactPhoneChange/types'

const initialState: ContactPhoneChange = {
  email: '',
  new_phone: '',
  new_account_status: '',
  new_memberid: '',
  old_phone: '',
  old_memberid: '',
  bank_status: '',
  bank_name: '',
  branch_name: '',
  charge_history_status: '',
  charge_history_date: new Date(),
  charge_history_money: '',
  payment_history_status: '',
  payment_history_date: new Date(),
  payment_history_money: '',
  payment_history_store: '',
  supay_balance_status: '',
  supay_balance: '',
  profile_status: '',
  profile_name: '',
  profile_birthday: new Date('1980/01/01'),
  require_files: [],
  other_files: [],
}

export const ContactPhoneChangeReducer = (
  state = initialState,
  action: ContactsPhoneChangeActionTypes
): ContactPhoneChange => {
  switch (action.type) {
    case ActionTypes.contactPhoneChange:
      return {
        ...state,
        email: action.payload.email,
        new_phone: action.payload.new_phone,
        new_account_status: action.payload.new_account_status,
        new_memberid: action.payload.new_memberid,
        old_phone: action.payload.old_phone,
        old_memberid: action.payload.old_memberid,
        bank_status: action.payload.bank_status,
        bank_name: action.payload.bank_name,
        branch_name: action.payload.branch_name,
        charge_history_status: action.payload.charge_history_status,
        charge_history_date: action.payload.charge_history_date,
        charge_history_money: action.payload.charge_history_money,
        payment_history_status: action.payload.payment_history_status,
        payment_history_date: action.payload.payment_history_date,
        payment_history_money: action.payload.payment_history_money,
        payment_history_store: action.payload.payment_history_store,
        supay_balance_status: action.payload.supay_balance_status,
        supay_balance: action.payload.supay_balance,
        profile_status: action.payload.profile_status,
        profile_name: action.payload.profile_name,
        profile_birthday: action.payload.profile_birthday,
        require_files: action.payload.require_files,
        other_files: action.payload.other_files,
      }
    case ActionTypes.clearState:
      return (state = initialState)
    default:
      return state
  }
}
