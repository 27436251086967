import React from 'react'
import { Provider } from 'react-redux'
import createStore from './src/store/index'
import { ApolloProvider } from '@apollo/client'
import { client } from '@/lib/apollo/apolloProxy'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

const wrapWithProvider = ({ element }) => {
  const store = createStore()
  dayjs.locale(ja)

  return (
    <>
      <ApolloProvider client={client}>
        <Provider store={store}>{element}</Provider>
      </ApolloProvider>
    </>
  )
}

export default wrapWithProvider
