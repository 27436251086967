import { combineReducers, createStore as reduxCreateStore } from 'redux'
import { ContactPhoneChangeReducer } from '@/store/contactPhoneChange/reducer'
import { ContactCancelReducer } from '@/store/contactCancel/reducer'
import { ContactCompensationReducer } from '@/store/contactCompensation/reducer'

const RootReducer = combineReducers({
  contactPhoneChange: ContactPhoneChangeReducer,
  contactCancel: ContactCancelReducer,
  contactCompensation: ContactCompensationReducer,
})

export type RootState = ReturnType<typeof RootReducer>

const createStore = () => reduxCreateStore(RootReducer)
export default createStore
