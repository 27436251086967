/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'ress'
import wrapWithProvider from './wrap-with-provider'
import axios from 'axios'

export const wrapRootElement = wrapWithProvider

export const onInitialClientRender = () => {
  axios.defaults.baseURL = process.env.GATSBY_BASE_URL
  axios.defaults.headers.common['X-Supay'] = process.env.GATSBY_X_SUPAY ?? ''
}
